import React from "react";
import { useMachine } from "@xstate/react";
import { inspect } from "@xstate/inspect";
import { createMachine } from "xstate";

inspect({
  iframe: false, // open in new window
});

const machine = createMachine({
  id: "wizard",
  initial: "dopiero_zaczynam_czy_optymalizacja",
  states: {
    dopiero_zaczynam_czy_optymalizacja: {
      on: {
        DOPIERO_ZACZYNAM: {
          target: "konkretne_obszary_czy_ogolne_wsparcie",
        },
        OPTYMALIZACJA: {
          target: "kategorie",
        },
      },
    },
    konkretne_obszary_czy_ogolne_wsparcie: {
      initial: "loading",
      on: {
        KONKRETNE_OBSZARY: {
          target: "kategorie",
        },
        OGOLNE_WSPARCIE: {
          target: "dorazna_pomoc_czy_przekazanie_procesu",
        },
      },
      states: {
        idle: {},
        loading: {
          tags: ["loading"],
          entry: ["asyncOp"],
          on: {
            IDLE: {
              target: "idle",
            },
          },
        },
      },
    },
    kategorie: {
      initial: "loading",
      on: {
        WYBRANO_KATEGORIE: {
          target: "dorazna_pomoc_czy_przekazanie_procesu",
        },
      },
      states: {
        idle: {},
        loading: {
          tags: ["loading"],
          entry: ["asyncOp"],
          on: {
            IDLE: {
              target: "idle",
            },
          },
        },
      },
    },
    dorazna_pomoc_czy_przekazanie_procesu: {
      initial: "loading",
      on: {
        END: {
          target: "end",
        },
      },
      states: {
        idle: {},
        end: {
          type: "final",
        },
        loading: {
          tags: ["loading"],
          entry: ["asyncOp"],
          on: {
            IDLE: {
              target: "idle",
            },
            EXIT: {
              target: "end",
            },
          },
        },
      },
      onDone: "end",
    },
    end: {},
  },
});

export const App = () => {
  const [current, send] = useMachine(machine, {
    devTools: true,
    actions: {
      asyncOp() {
        setTimeout(() => {
          send("IDLE");
        }, 1000);
      },
    },
  });

  return (
    <div>
      {current.hasTag("loading") ? (
        <div>Loading</div>
      ) : (
        <>
          {current.matches("dopiero_zaczynam_czy_optymalizacja") && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const form = e.target as HTMLFormElement;
                const formData = new FormData(form);
                const val = formData.get("dopiero_zaczynam_czy_optymalizacja");
                if (val === "getting_started") {
                  send("DOPIERO_ZACZYNAM");
                } else if (val == "optimization") {
                  send("OPTYMALIZACJA");
                }
              }}
            >
              <h2>Dopiero zaczynasz czy optymalizacja?</h2>
              <label htmlFor="getting_started">
                Dopiero zaczynam
                <input
                  type="radio"
                  name="dopiero_zaczynam_czy_optymalizacja"
                  id="getting_started"
                  value="getting_started"
                />
              </label>
              <label htmlFor="optimization">
                Optymalizacja
                <input
                  type="radio"
                  name="dopiero_zaczynam_czy_optymalizacja"
                  value="optimization"
                />
              </label>
              <button type="submit">Dalej</button>
            </form>
          )}
          {current.matches("konkretne_obszary_czy_ogolne_wsparcie") && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const form = e.target as HTMLFormElement;
                const formData = new FormData(form);
                const val = formData.get("general_or_detail");
                if (val === "general") {
                  send("OGOLNE_WSPARCIE");
                } else if (val == "detail") {
                  send("KONKRETNE_OBSZARY");
                }
              }}
            >
              <h2>Ogólna pomoc czy konkretna?</h2>
              <label htmlFor="general">
                Ogólna pomoc
                <input
                  type="radio"
                  name="general_or_detail"
                  id="general"
                  value="general"
                />
              </label>
              <label htmlFor="optimization">
                Konkretna pomoc (kategorie)
                <input type="radio" name="general_or_detail" value="detail" />
              </label>
              <button type="submit">Dalej</button>
            </form>
          )}
          {current.matches("kategorie") && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                send("WYBRANO_KATEGORIE");
              }}
            >
              <h2>Kategorie pomocy?</h2>
              <label htmlFor="copywriting">
                Copywriting
                <input
                  type="checkbox"
                  name="categories"
                  id="copywriting"
                  value="copywriting"
                />
              </label>
              <label htmlFor="optimization">
                Optymalizacja kampanii
                <input
                  type="checkbox"
                  name="categories"
                  id="campaign_optimization"
                  value="campaign_optimization"
                />
              </label>
              <label htmlFor="deliverability">
                Dostarczalnosc
                <input
                  type="checkbox"
                  name="categories"
                  id="deliverability"
                  value="deliverability"
                />
              </label>
              <button type="submit">Dalej</button>
            </form>
          )}
          {current.matches("dorazna_pomoc_czy_przekazanie_procesu") && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                send("END");
              }}
            >
              <h2>Doraźna pomoc czy przekazanie procesu?</h2>
              <label htmlFor="general">
                Doraźna
                <input
                  type="radio"
                  name="ad_hoc_or_whole_process"
                  id="ad_hoc"
                  value="ad_hoc"
                />
              </label>
              <label htmlFor="optimization">
                Cały proces
                <input
                  type="radio"
                  name="ad_hoc_or_whole_process"
                  id="whole_process"
                  value="whole_process"
                />
              </label>
              <button type="submit">Dalej</button>
            </form>
          )}
          {current.matches("end") && <div>KONIEC</div>}
        </>
      )}
    </div>
  );
};
