import { __assign, __values, __rest } from './_virtual/_tslib.js';
import { stringify } from './utils.js';

function selectivelyStringify(value, keys, replacer) {
    var e_1, _a;
    var selected = {};
    try {
        for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
            var key = keys_1_1.value;
            selected[key] = value[key];
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var serialized = JSON.parse(stringify(selected, replacer));
    return stringify(__assign(__assign({}, value), serialized));
}
function stringifyState(state, replacer) {
    state.machine; state.configuration; state.history; var stateToStringify = __rest(state, ["machine", "configuration", "history"]);
    return selectivelyStringify(stateToStringify, ['context', 'event', '_event'], replacer);
}
function stringifyMachine(machine, replacer) {
    return selectivelyStringify(machine.definition, ['context'], replacer);
}

export { selectivelyStringify, stringifyMachine, stringifyState };
